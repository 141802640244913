<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import Loader from '@/components/widgets/loader';

import productGetFindByCategoryId from '../../../state/api/productGetFindByCategoryId';

export default {
    page: {
        title: "Contact Users List",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Loader },
    data() {
        return {
            title: "Product List",
            items: [
                {
                text: "Products",
                href: "/"
                },
                {
                text: "Product List",
                active: true
                }
            ],
            productCategoryList : [],
            id:this.$route.params.id,
        };        
    },
    methods:{
        async getProductFindById(){
            const {getProductFindById} = productGetFindByCategoryId()
            const response = await getProductFindById(this.id);
            this.productCategoryList = response.data;      
        }
        
    },
    mounted(){
        this.getProductFindById();
    }
}
</script>

<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
        <Loader :loading="productCategoryList">
            <div class="row">       
                <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="btn-group mb-3">
                            <router-link class="btn btn-light dropdown-toggle btn-block" :to="{name:'add-manager'}">
                                <i class="mdi mdi-plus me-1"></i> {{$t('user.UserNew')}}
                            </router-link>
                            <!-- <a class="btn btn-light dropdown-toggle btn-block" href="/user/add-manager/">
                                <i class="mdi mdi-plus me-1"></i> {{$t('user.UserNew')}}
                            </a> -->
                        </div>               
                        <div class="table-responsive">
                            <table class="table align-middle table-nowrap table-hover">
                            <thead class="table-light">
                                <tr>
                                <th scope="col">{{$t('user.Name')}}</th>
                                <th scope="col">{{$t('user.Status')}}</th>
                                <th scope="col">{{$t('user.Action')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cat in productCategoryList" :key="cat.id">
                                    <td>
                                        <h5 class="font-size-14 mb-1">
                                            <router-link class="text-dark" :to="{name:'category-detail',params:{id:cat.id}}">
                                                {{cat.name}}
                                            </router-link>
                                        <!-- <a :href="'/category/category-detail/' + cat.id" class="text-dark">{{cat.name}}</a> -->
                                        </h5>
                                    </td>
                                    <td v-if="cat.isActive">
                                        <span class="badge bg-success">{{$t('user.Active')}}</span>
                                    </td>
                                    <td v-else>
                                        <span class="badge bg-danger">{{$t('user.Passive')}}</span>
                                    </td>
                                    <td>
                                        <ul class="list-inline font-size-20 contact-links mb-0">
                                        <li class="list-inline-item px-2">
                                            <router-link v-b-tooltip.hover :title="$t('menuitems.edit.text')" :to="{name:'category-detail',params:{id:cat.id}}">
                                                <i class="bx bx-edit-alt"></i>
                                            </router-link>
                                            <!-- <a :href="'/category/category-detail/' + cat.id" v-b-tooltip.hover :title="$t('menuitems.edit.text')">
                                                <i class="bx bx-edit-alt"></i>
                                            </a> -->
                                        </li>
                                        <li class="list-inline-item px-2">
                                            <a href="javascript:void(0);" v-b-tooltip.hover :title="$t('user.AddGallery')">
                                                <i class="bx bx-images"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item px-2">
                                            <a href="javascript:void(0);" v-b-tooltip.hover :title="$t('user.Delete')">
                                                <i class="bx bx-trash-alt"></i>
                                            </a>                                            
                                            <!-- <a :href="'products/products-list/' + cat.id" v-b-tooltip.hover :title="$t('menuitems.ecommerce.list.products')" @click="deleteUser(cat.id)">
                                                <i class="bx bx-unlink"></i>
                                            </a> -->
                                        </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="row d-none">
                            <div class="col-lg-12">
                            <ul class="pagination pagination-rounded justify-content-center mt-4">
                                <li class="page-item disabled">
                                <a href="#" class="page-link">
                                    <i class="mdi mdi-chevron-left"></i>
                                </a>
                                </li>
                                <li class="page-item">
                                <a href="#" class="page-link">1</a>
                                </li>
                                <li class="page-item active">
                                <a href="#" class="page-link">2</a>
                                </li>
                                <li class="page-item">
                                <a href="#" class="page-link">3</a>
                                </li>
                                <li class="page-item">
                                <a href="#" class="page-link">4</a>
                                </li>
                                <li class="page-item">
                                <a href="#" class="page-link">5</a>
                                </li>
                                <li class="page-item">
                                <a href="#" class="page-link">
                                    <i class="mdi mdi-chevron-right"></i>
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Loader>      
    </Layout>
  </template>
